<div style="overflow:hidden;height: 95vh;" class="row">
  <div *ngFor="let img of gridImages" class="col-1 nopadding">

    <div class="d-flex h-100">
      <div class="mx-auto my-auto">
        <img src="assets/sources/{{img}}" class="rounded img-fluid opacity-20">
      </div>
    </div>
  </div>
</div>

<div class="outer-div">
  <div class="inner-div">
    <div class="az-signin-wrapper">
      <div class="az-card-signin">
        <h1 class="az-logo">fasten</h1>
        <div class="az-signin-header">
          <h2>Let's Get Started!</h2>
          <p>Create your Fasten Health administrator account</p>

          <form (ngSubmit)="signupSubmit()" #userForm="ngForm">
            <div class="form-group">
              <input [(ngModel)]="newUser.full_name" name="full_name" #full_name="ngModel" required minlength="2" type="text" class="form-control" placeholder="Name">

              <div *ngIf="full_name.invalid && (full_name.dirty || full_name.touched)" class="alert alert-danger">
                <div *ngIf="full_name.errors?.['required']">
                  Name is required.
                </div>
                <div *ngIf="full_name.errors?.['minlength']">
                  Name must be at least 2 characters long.
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newUser.username" name="username" #username="ngModel" required minlength="4" type="text" class="form-control" autocapitalize="none" placeholder="Username">
              <div *ngIf="username.invalid && (username.dirty || username.touched)" class="alert alert-danger">
                <div *ngIf="username.errors?.['required']">
                  Username is required.
                </div>
                <div *ngIf="username.errors?.['minlength']">
                  Username must be at least 4 characters long.
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newUser.email" name="email" #email="ngModel" required email minlength="4" type="email" class="form-control" autocapitalize="none" placeholder="Email Address">
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors?.['required']">
                  Email is required.
                </div>
                <div *ngIf="email.errors?.['minlength']">
                  Email must be at least 4 characters long.
                </div>
                <div *ngIf="email.errors?.['email']">
                  Email must be a valid email address.
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newUser.password" name="password" #password="ngModel" required minlength="8" type="password" class="form-control" placeholder="Password">
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                <div *ngIf="password.errors?.['required']">
                  Password is required.
                </div>
                <div *ngIf="password.errors?.['minlength']">
                  Password must be at least 8 characters long.
                </div>
              </div>
            </div><!-- form-group -->

            <div class="form-group form-check">
              <input [(ngModel)]="newUser.agree_terms" name="agree_terms" #agree_terms="ngModel" type="checkbox" class="form-check-input" id="agreeTermsCheck" required>
              <label class="form-check-label" for="agreeTermsCheck">
                I have read and agree to the Fasten Health <br/> <a externalLink href="https://policy.fastenhealth.com/privacy_policy.html">Privacy Policy</a>
                and <a externalLink href="https://policy.fastenhealth.com/terms.html">Terms of Service</a>
              </label>

              <div *ngIf="agree_terms.invalid && (agree_terms.dirty || agree_terms.touched)" class="alert alert-danger">
                <div *ngIf="agree_terms.errors?.['required']">
                  You must agree to the Privacy Policy and Terms of Use.
                </div>
              </div>
            </div>


            <div class="form-group form-check">
              <input [(ngModel)]="newUser.join_mailing_list" name="join_mailing_list" #join_mailing_list="ngModel" type="checkbox" class="form-check-input" id="joinMailingListCheck">
              <label class="form-check-label" for="joinMailingListCheck">
                Join Mailing List
              </label>
            </div>

            <button [disabled]="!userForm.form.valid || loading" type="submit" class="btn btn-az-primary btn-block">Create Account</button>

            <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
              <strong>Error</strong> {{errorMsg}}
            </div>
          </form>

        </div><!-- az-signin-header -->
      </div><!-- az-card-signin -->
    </div><!-- az-signin-wrapper -->

  </div>
</div>



