<div class="card h-100 d-flex align-items-center justify-content-center mt-3 mt-3 rounded-0 cursor-pointer" [ngClass]="{'card-disable': sourceInfo?.brand?.hidden}">
  <div (click)="onCardClick()" class="card-body" [class.border-left-danger]="status == 'failed'">

    <div class="h-100 d-flex align-items-center">
      <img imageFallback [src]="'assets/sources/'+((sourceInfo?.brand?.id || sourceInfo?.source?.brand_id )+'.png')" [alt]="sourceInfo?.brand?.name" class="img-fluid">
      <div *ngIf="status == 'failed'"  class="card-img-overlay">
        <span class="badge badge-danger">failed</span>
      </div>
    </div>
    <div *ngIf="status == 'authorize' || status  == 'token'" class="progress">
      <div [style.width]="status == 'authorize' ? '33%' : '66%'" class="bg-indigo progress-bar progress-bar-striped progress-bar-animated" role="progressbar"></div>
    </div>
  </div>
  <div class="card-footer text-center p-1" style="width:100%">
    <small class="tx-gray-700">
      {{getSourceDisplayName(sourceInfo)}}
    </small>
    <ng-container *ngIf="sourceInfo?.searchHighlights?.length > 0">
      <br/>
      <small class="tx-gray-700"><strong>Also: </strong><span [innerHTML]="sourceInfo?.searchHighlights.join(', ') | safeHtml:'html'"></span></small>
    </ng-container>

  </div>
</div>
