<div class="row">
  <div class="col text-center">
    <div class="btn-group" role="group" aria-label="Basic example">
      <button ngbTooltip="scroll layers" type="button" (click)="onChangeTool('Scroll')" class="btn btn-secondary pd-x-25" [class.active]="selectedTool == 'Scroll'"><i class="fas fa-bars"></i></button>
      <button ngbTooltip="zoom and pan" type="button" (click)="onChangeTool('ZoomAndPan')" class="btn btn-secondary pd-x-25" [class.active]="selectedTool == 'ZoomAndPan'"><i class="fas fa-search"></i></button>
      <button ngbTooltip="change brightness" type="button" (click)="onChangeTool('WindowLevel')" class="btn btn-secondary pd-x-25" [class.active]="selectedTool == 'WindowLevel'"><i class="fas fa-adjust"></i></button>
      <button ngbTooltip="add measurements" type="button" (click)="onChangeTool('Draw')" class="btn btn-secondary pd-x-25" [class.active]="selectedTool == 'Draw'"><i class="fas fa-edit"></i></button>
    </div>

    <div class="btn-group" role="group" aria-label="Basic example">
      <button ngbTooltip="toggle orientation" type="button" (click)="toggleOrientation()" class="btn btn-secondary pd-x-25"><i class="fas fa-compress"></i></button>
    </div>

    <div class="btn-group" role="group" aria-label="Info">
      <button ngbTooltip="show info" type="button" (click)="openTagsModal(tagsModal)" class="btn btn-secondary pd-x-25"><i class="fas fa-info"></i></button>
    </div>
  </div>
</div>

<div id="dwv">
<!--  <mat-progress-bar mode="determinate" value="{{ loadProgress }}"></mat-progress-bar>-->
<!--  <div class="button-row">-->
<!--    <mat-button-toggle-group name="tool" [disabled]="!dataLoaded">-->
<!--      <mat-button-toggle value="{{ tool }}" color="primary"-->
<!--                         *ngFor="let tool of toolNames"-->
<!--                         title="{{ tool }}"-->
<!--                         (click)="onChangeTool(tool)"-->
<!--                         [disabled]="!dataLoaded || !canRunTool(tool)">-->
<!--        <mat-icon>{{ getToolIcon(tool) }}</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--    </mat-button-toggle-group>-->
  <div id="layerGroup0" class="layerGroup pd-t-10">
    <div id="dropBox"></div>
  </div>
</div>


<ng-template #tagsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">DICOM tags</h4>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-striped mg-b-0">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of visibleMetaData">
          <th scope="row">{{row.id}}</th>
          <td>{{row.name}}</td>
          <td>{{row.value}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <ngb-pagination
      class="mr-auto"
      [collectionSize]="metaData.length"
      [(page)]="tagsPage"
      [maxSize]="15"
      [pageSize]="tagsPageSize"
      (pageChange)="refreshTags()"
    >
    </ngb-pagination>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
