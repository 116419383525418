<div class="card card-fhir-resource" >
  <div class="card-header" (click)="isCollapsed = ! isCollapsed">
    <div>
      <h6 class="card-title">{{displayModel?.title}}</h6>
      <p class="card-text tx-gray-400" *ngIf="displayModel?.sort_date"><strong>Start date</strong> {{displayModel?.sort_date | date}}</p>
    </div>
    <fhir-ui-badge class="float-right" [status]="displayModel?.status">{{displayModel?.status}}</fhir-ui-badge>
<!--    <div class="btn-group">-->
<!--      <button class="btn active">Day</button>-->
<!--      <button class="btn">Week</button>-->
<!--      <button class="btn">Month</button>-->
<!--    </div>-->
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="card-body">
    <p class="az-content-text mg-b-20">Describes the event of a patient being administered a vaccine or a record of an immunization as reported by a patient, a clinician or another party.</p>
    <fhir-ui-table [displayModel]="displayModel" [tableData]="tableData"></fhir-ui-table>
  </div>
  <div *ngIf="showDetails" class="card-footer">
    <a class="float-right" [routerLink]="['/explore', displayModel?.source_id, 'resource', displayModel?.source_resource_id]">details</a>
  </div>
</div>
