<div *ngIf="loading">
  <div class="text-center mg-b-20">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>



<ng-container *ngIf="!loading" [ngTemplateOutlet]="
  displayModel?.content_type == 'application/pdf' ? showPdf :
  displayModel?.content_type == 'text/markdown' ? showMarkdown :
  displayModel?.content_type == 'text/plain' ? showText :
  displayModel?.content_type == 'application/dicom' ? showDicom :
  displayModel?.content_type == 'text/rtf' ? showRtf :
  (displayModel?.content_type == 'text/html' || displayModel?.content_type == 'application/html') ? showHtml :
  (displayModel?.content_type == 'application/xml' || displayModel?.content_type == 'application/json') ? showHighlight :
  (displayModel?.content_type == 'image/jpeg' || displayModel?.content_type == 'image/png') ? showImg :
  showEmpty
"></ng-container>

<ng-template #showPdf>
  <fhir-pdf [displayModel]="displayModel"></fhir-pdf>
</ng-template>
<ng-template #showImg>
  <fhir-img [displayModel]="displayModel"></fhir-img>
</ng-template>
<ng-template #showHtml>
  <fhir-html [displayModel]="displayModel"></fhir-html>
</ng-template>
<ng-template #showMarkdown>
  <fhir-markdown [displayModel]="displayModel"></fhir-markdown>
</ng-template>
<ng-template #showHighlight>
  <pre><code [languages]="['json', 'xml']" [highlight]="displayModel.content"></code></pre>
</ng-template>
<ng-template #showText>
  <fhir-binary-text [displayModel]="displayModel"></fhir-binary-text>
</ng-template>
<ng-template #showDicom>
  <fhir-dicom [displayModel]="displayModel"></fhir-dicom>
</ng-template>
<ng-template #showRtf>
  <fhir-rtf [displayModel]="displayModel"></fhir-rtf>
</ng-template>
<ng-template #showEmpty>
  Unknown Binary content type {{displayModel?.content_type}}
</ng-template>
