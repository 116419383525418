<div class="card card-dashboard-seven mb-3">
  <div class="card-header tx-medium">
    <div class="row" >
      <!-- Condition Header -->
      <div class="col-6">
        <span [routerLink]="['/explore', firstObservation?.source_id, 'resource', firstObservation?.source_resource_id]">{{observationTitle}}</span>
      </div>
      <div class="col-6">
        {{firstObservation | fhirPath: "Observation.effectiveDateTime": "Observation.issued" |  date}}
      </div>
    </div>
  </div><!-- card-header -->
  <div class="card-body">

    <div class="row">
      <!-- Condition Details -->

      <div class="col-6 mb-2">

        <div class="row  pl-3">
          <div class="col-12 mt-3 mb-2">
            <p>
              <strong>Short Name:</strong> {{firstObservation | fhirPath: "Observation.code.text"}} <br/>
              <strong>Result:</strong> {{firstObservation | fhirPath: "Observation.valueQuantity.value"}} {{firstObservation | fhirPath: "Observation.valueQuantity.unit"}} <br/>
              <br/>
              <strong>Latest Test Date:</strong> {{firstObservation | fhirPath: "Observation.effectiveDateTime": "Observation.issued" |  date}} <br/>
              <strong>Ordered By:</strong> {{firstObservation | fhirPath: "Observation.encounter.display"}} <br/>
              <strong>LOINC Code:</strong> {{observationCode}} <br/>
              <strong>Notes:</strong> {{firstObservation | fhirPath: "Observation.note.text"}}

              <br/>
              <br/>
              <a class="cursor-pointer tx-indigo" (click)="collapse.toggle()">show all</a>
            </p>

            <div #collapse="ngbCollapse" [ngbCollapse]="true">

              <ul>
                <li class="cursor-pointer tx-indigo" *ngFor="let observation of observations">
                  <a [routerLink]="['/explore', observation?.source_id, 'resource', observation?.source_resource_id]">
                    Observation: {{observation | fhirPath: "Observation.effectiveDateTime": "Observation.issued" | date}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="col-6 bg-gray-100">
        <div class="row">
          <div *ngIf="observationCode" class="col-12 mt-3">
            <app-glossary-lookup [code]="observationCode" [codeSystem]="'http://loinc.org'"></app-glossary-lookup>
          </div>

          <div class="col-12 visualization-container">
            <observation-visualization [observations]="observationModels"></observation-visualization>
          </div>

        </div>
      </div>
    </div>

  </div><!-- card-body -->
</div>
