<h2 class="az-content-title mg-t-40">{{resourceListType}}</h2>

<div *ngIf="knownResourceType; else unknownResourceType" class="alert alert-info" role="alert">
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">×</span>
  </button>
  <strong>Work-in-Progress!</strong> Fasten is still under active development, and some of these tables may be incomplete.
  <br/>
  You can click on a row to see the raw data.
  <br/>
  If you have any feedback regarding the data displayed, please <a href="https://github.com/fastenhealth/fasten-onprem/issues" externalLink>file a ticket <i class="fab fa-github"></i></a>
</div>



<ng-template #unknownResourceType>
  <div class="alert alert-warning" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
    <strong>Warning!</strong> Fasten does not know how to display this resource type (yet). Click an item in the list below to see the raw data
  </div>
</ng-template>

<ng-template fhirDatatableOutlet></ng-template>
