<div [formGroup]="filterService.filterForm" class="az-content-left az-content-left-components sticky-top" style="position:sticky;">
  <div class="component-item">
    <label>Connected</label>
    <nav class="nav flex-column">
      <a ngbTooltip="not yet implemented" routerLinkActive="active" class="nav-link">Active Sources</a>
      <a ngbTooltip="not yet implemented" routerLinkActive="active" class="nav-link">Manual</a>
    </nav>
    <label>Categories</label>
    <nav formGroupName="categories" class="nav flex-column">

      <div *ngFor="let category of filterService.filterForm.get('categories').value | keyvalue " class="switch-container">
        <label class="switch text-nowrap">
          <input type="checkbox" [formControlName]="category.key" [value]="category.value">
          <span class="switch-button"></span><span class="category-label truncate  pl-1 pr-1">{{category.key | medicalSourcesCategoryLookup}}</span>
          <span *ngIf="!filterService.filterForm.get('categories').get(category.key).value" class="badge badge-primary mr-1">{{bucketDocCount(categories, category.key)}}</span>
        </label>
      </div>
    </nav>

    <label>Platform</label>
    <nav formGroupName="platformTypes" class="nav flex-column">
      <div *ngFor="let platformType of filterService.filterForm.get('platformTypes').value | keyvalue " class="switch-container">
        <label class="switch text-nowrap">
          <input type="checkbox" [formControlName]="platformType.key" [value]="platformType.value">
          <span class="switch-button"></span><span class="category-label truncate  pl-1 pr-1">{{platformType.key}}</span>
          <span *ngIf="!filterService.filterForm.get('platformTypes').get(platformType.key).value" class="badge badge-primary mr-1">{{bucketDocCount(platformTypes, platformType.key)}}</span>
        </label>
      </div>
    </nav>

    <label>Industry</label>
    <nav class="nav flex-column">
      <a ngbTooltip="not yet implemented" routerLinkActive="active" class="nav-link">Medical</a>
      <a ngbTooltip="not yet implemented" routerLinkActive="active" class="nav-link">Dental</a>
      <a ngbTooltip="not yet implemented" routerLinkActive="active" class="nav-link">Vision</a>
      <a ngbTooltip="not yet implemented" routerLinkActive="active" class="nav-link">Mental</a>
    </nav>

  </div><!-- component-item -->

</div><!-- az-content-left -->
