<div class="card card-fhir-resource" >
  <div class="card-header" (click)="isCollapsed = ! isCollapsed">
    <div>
      <h6 class="card-title">{{displayModel?.sort_title}}</h6>
<!--      <p class="card-text tx-gray-400" *ngIf="displayModel?.created_at"><strong>Created at</strong> {{displayModel?.created_at | date}}</p>-->
    </div>
    <fhir-ui-badge class="float-right" [status]="displayModel?.status">{{displayModel?.status}}</fhir-ui-badge>
    <!--    <div class="btn-group">-->
    <!--      <button class="btn active">Day</button>-->
    <!--      <button class="btn">Week</button>-->
    <!--      <button class="btn">Month</button>-->
    <!--    </div>-->
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="card-body">
    <p class="az-content-text mg-b-20">An action that is or was performed on or for a patient, practitioner, device, organization, or location. For example, this can be a physical intervention on a patient like an operation, or less invasive like long term services, counseling, or hypnotherapy.</p>
    <fhir-ui-table [displayModel]="displayModel" [tableData]="tableData"></fhir-ui-table>

    <div *ngIf="!showDetails">
      <fhir-binary [attachmentModel]="displayModel.content" [attachmentSourceId]="displayModel?.source_id"></fhir-binary>
    </div>
  </div>
  <div *ngIf="showDetails" class="card-footer">
    <a class="float-right" [routerLink]="['/explore', displayModel?.source_id, 'resource', displayModel?.source_resource_id]">details</a>
  </div>
</div>
