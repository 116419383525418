<div class="az-signin-wrapper">
  <div class="az-card-signin" [ngStyle]="{'height': showExternalIdP ? 'unset' : '560px' }">
    <h1 class="az-logo">fasten</h1>
    <div class="az-signin-header">
      <h2>{{showExternalIdP ? "Welcome!" : "Welcome back!"}}</h2>
      <h4 *ngIf="!loading">Please sign in to continue</h4>

      <form *ngIf="showExternalIdP">
        <div class="pt-4 pb-3" *ngIf="!loading else isLoadingTemplate">
          <p class="pb-3">You can use the button below to create an account or signin to your existing Fasten account.</p>
          <div class="hello-container">
            <button (click)="idpConnectHello($event)" class="hello-btn"></button>
            <button class="hello-about"></button>
          </div>
        </div>
        <ng-template #isLoadingTemplate>
          <div class="text-center">
            <p class="pb-3">Please wait, connecting account...</p>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </ng-template>
      </form>

      <form  *ngIf="!showExternalIdP" (ngSubmit)="signinSubmit()" #userForm="ngForm">
        <div class="form-group">
          <label>Username</label>
          <input [(ngModel)]="existingUser.username" name="username" #username="ngModel" required autocapitalize="none" minlength="2" type="text" class="form-control" placeholder="Enter your username">

          <div *ngIf="username.invalid && (username.dirty || username.touched)" class="alert alert-danger">
            <div *ngIf="username.errors?.['required']">
              Username is required.
            </div>
            <div *ngIf="username.errors?.['minlength']">
              Username must be at least 4 characters long.
            </div>
          </div>
        </div><!-- form-group -->
        <div class="form-group">
          <label>Password</label>
          <input [(ngModel)]="existingUser.password" name="password" #password="ngModel" required minlength="8" type="password" class="form-control" placeholder="Enter your password">

          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
            <div *ngIf="password.errors?.['required']">
              Password is required.
            </div>
            <div *ngIf="password.errors?.['minlength']">
              Password must be at least 8 characters long.
            </div>
          </div>
        </div><!-- form-group -->
        <button [disabled]="!userForm.form.valid || loading" type="submit" class="btn btn-az-primary btn-block">Sign In</button>

        <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
          <strong>Error</strong> {{errorMsg}}
        </div>
      </form>

    </div><!-- az-signin-header -->
    <div *ngIf="!showExternalIdP" class="az-signin-footer">
      <p><a ngbTooltip="not yet implemented">Forgot password?</a></p>
      <p>Don't have an account? <a routerLink="/auth/signup">Create an Account</a></p>
    </div><!-- az-signin-footer -->
  </div><!-- az-card-signin -->
</div><!-- az-signin-wrapper -->
