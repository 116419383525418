<div class="card card-fhir-resource" >
  <div class="card-header" (click)="isCollapsed = ! isCollapsed">
    <div>
      <h6 class="card-title">{{displayModel?.sort_title || displayModel?.name?.[0]?.displayName}}</h6>
      <p class="card-text tx-gray-400" *ngIf="displayModel?.sort_date"><strong>Date</strong>{{displayModel?.sort_date}}</p>
    </div>
    <div>
      <fhir-ui-badge [status]="displayModel?.status">{{displayModel?.status}}</fhir-ui-badge>
      <ng-content select="[header]"></ng-content>
    </div>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="card-body">
    <p class="az-content-text mg-b-20">A person who is directly or indirectly involved in the provisioning of healthcare.</p>
    <fhir-ui-table [displayModel]="displayModel" [tableData]="tableData"></fhir-ui-table>
  </div>
  <div *ngIf="showDetails" class="card-footer">
    <a class="float-right" [routerLink]="['/explore', displayModel?.source_id, 'resource', displayModel?.source_resource_id]">details</a>
  </div>
</div>
