<div class="card card-fhir-resource" >
  <div class="card-header" (click)="isCollapsed = ! isCollapsed">
    <div>
      <h6 class="card-title">{{displayModel?.title}}</h6>
      <p class="card-text tx-gray-400" *ngIf="displayModel?.recorded_date"><strong>Recorded on</strong> {{displayModel?.recorded_date | date}}</p>
    </div>
    <fhir-ui-badge class="float-right" [status]="displayModel?.status">{{displayModel?.status}}</fhir-ui-badge>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="card-body">
    <p class="az-content-text mg-b-20">Risk of harmful or undesirable physiological response which is specific to an individual and associated with exposure to a substance.</p>
    <fhir-ui-table [displayModel]="displayModel" [tableData]="tableData"></fhir-ui-table>
  </div>
  <div *ngIf="showDetails" class="card-footer">
    <a class="float-right" [routerLink]="['/explore', displayModel?.source_id, 'resource', displayModel?.source_resource_id]">details</a>
  </div>
</div>
