<div>
  <embed
    [src]="safeUrl"
    type="{{displayModel?.content_type}}"
    [style]="{
      width: '100%',
      height: height + 'px'
    }"
  />
</div>
