<div class="card  card-fhir-resource">
  <div class="card-header" (click)="isCollapsed = ! isCollapsed">
    <div>
      <h6 class="card-title">{{displayModel?.sort_title || displayModel?.code?.text}}</h6>
      <p class="card-text tx-gray-400" *ngIf="displayModel?.period_start"><strong>Start date</strong> {{displayModel?.period_start | date}}</p>
    </div>
    <!--    <div class="btn-group">-->
    <!--      <button class="btn active">Day</button>-->
    <!--      <button class="btn">Week</button>-->
    <!--      <button class="btn">Month</button>-->
    <!--    </div>-->
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="card-body">
    <fhir-ui-table [displayModel]="displayModel" [tableData]="tableData"></fhir-ui-table>
  </div>
  <div *ngIf="showDetails" class="card-footer">
    <a class="float-right" [routerLink]="['/explore', displayModel?.source_id, 'resource', displayModel?.source_resource_id]">details</a>
  </div>
</div>
