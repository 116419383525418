<div class="az-content">
  <div class="container">
    <div class="az-content-body pd-lg-l-40 d-flex flex-column">
      <div class="az-content-breadcrumb">
        <span>Components</span>
        <span>Forms</span>
        <span>Form Elements</span>
      </div>
      <h2 class="az-content-title">Create a Record</h2>

    </div><!-- az-content-body -->
  </div><!-- container -->
</div><!-- az-content -->

