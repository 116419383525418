<div class="card bg-gray-100">
  <div class="card-body">
    <div class="text-center tx-indigo">
      <p class="pb-3">{{loadingTitle}}</p>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div><!-- card-body -->
</div>

