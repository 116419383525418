<div class="az-signup-wrapper">
  <div class="az-column-signup-left">
    <div>
      <i class="typcn typcn-chart-bar-outline"></i>
      <h1 class="az-logo">fasten</h1>
      <h5>Access, aggregate and track your medical records.</h5>
      <p>Import your medical, dental and vision history from your various health providers.
        Your data is always stored safely and securely on your computer.
      </p>
      <p>Build one consolidated health record of your full health history, supports multiple users, create accounts for your whole family
      </p>
      <a href="https://www.fastenhealth.com/" class="btn btn-outline-indigo" externalLink>Learn More</a>
    </div>
  </div><!-- az-column-signup-left -->
  <div class="az-column-signup">
    <h1 class="az-logo">fasten</h1>
    <div class="az-signup-header">
      <h2>It's your health. Own it.</h2>
      <h4>Create an account, and get started.</h4>

      <form (ngSubmit)="signupSubmit()" #userForm="ngForm">

        <div class="form-group">
          <label>Your name</label>
          <input [(ngModel)]="newUser.full_name" name="full_name" #full_name="ngModel" required minlength="2" type="text" class="form-control" placeholder="Enter your firstname and lastname">

          <div *ngIf="full_name.invalid && (full_name.dirty || full_name.touched)" class="alert alert-danger">
            <div *ngIf="full_name.errors?.['required']">
              Name is required.
            </div>
            <div *ngIf="full_name.errors?.['minlength']">
              Name must be at least 2 characters long.
            </div>
          </div>
        </div><!-- form-group -->

        <div class="form-group">
          <label>Username</label>
          <input [(ngModel)]="newUser.username" name="username" #username="ngModel" required minlength="4" type="text" class="form-control" autocapitalize="none" placeholder="Enter your username">

          <div *ngIf="username.invalid && (username.dirty || username.touched)" class="alert alert-danger">
            <div *ngIf="username.errors?.['required']">
              Username is required.
            </div>
            <div *ngIf="username.errors?.['minlength']">
              Username must be at least 4 characters long.
            </div>
          </div>

        </div><!-- form-group -->
        <div class="form-group">
          <label>Password</label>
          <input [(ngModel)]="newUser.password" name="password" #password="ngModel" required minlength="8" type="password" class="form-control" placeholder="Enter your password">

          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
            <div *ngIf="password.errors?.['required']">
              Password is required.
            </div>
            <div *ngIf="password.errors?.['minlength']">
              Password must be at least 8 characters long.
            </div>
          </div>
        </div><!-- form-group -->
        <button [disabled]="!userForm.form.valid || loading" type="submit" class="btn btn-az-primary btn-block">Create Account</button>

        <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
          <strong>Error</strong> {{errorMsg}}
        </div>
      </form>
    </div><!-- az-signup-header -->
    <div class="az-signup-footer">
      <p>Already have an account? <a routerLink="/auth/signin">Sign In</a></p>
    </div><!-- az-signin-footer -->
  </div><!-- az-column-signup -->
</div><!-- az-signup-wrapper -->
