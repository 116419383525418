<h2 class="az-content-title">Connected Sources</h2>

<div *ngIf="!loading else isLoadingTemplate" class="row">
  <app-medical-sources-card class="col-sm-3 mg-b-20 px-3"
                            *ngFor="let sourceData of connectedSourceList"
                            [sourceInfo]="sourceData"
                            [status]="status[sourceData.source?.id] || status[sourceData.brand?.id]"
                            (onClick)="openModal(contentModalRef, $event)"
  ></app-medical-sources-card>
</div>


<ng-template #contentModalRef let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{modalSelectedSourceListItem?.source?.display || modalSelectedSourceListItem?.brand?.name}}</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div *ngIf="modalSelectedSourceListItem?.source?.platform_type != 'fasten'; else fastenSourceDescription" class="modal-body">
    <h6>Manage Source</h6>
    <p>Existing connections can be "Synced", "Reconnected" or "Deleted"</p>
    <ul>
      <li><p><strong>Sync</strong> - Download all resources from this healthcare provider, storing them securely in Fasten</p></li>
      <li><p><strong>Reconnect</strong> - If your healthcare connection has expired, you can use this button to reconnect</p></li>
      <li><p><strong>Delete</strong> - Delete all resources for this healthcare provider. This will ONLY effect data stored in Fasten</p></li>
    </ul>
  </div>
  <ng-template #fastenSourceDescription>
    <div class="modal-body">
      <h6>Fasten Health</h6>
      <p>This source contains all medical records that are created manually in the Fasten Health app.
      It will be empty initially.</p>
      <ul>
        <li><p><strong>Explore</strong> - View the medical records that you have created</p></li>
      </ul>
    </div>
  </ng-template>

  <div class="modal-footer">

    <a [routerLink]="['/explore', modalSelectedSourceListItem?.source?.id]" (click)="modal.close()" class="btn btn-indigo mr-auto">Explore</a>


    <div *ngIf="modalSelectedSourceListItem?.source?.platform_type != 'fasten'" class="d-inline-block" ngbDropdown>
      <button
        type="button"
        class="btn btn-outline-indigo"
        id="dropdownManual"
        ngbDropdownToggle>
        Actions
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownManual">
        <button *ngIf="modalSelectedSourceListItem?.source?.platform_type != 'manual'" ngbDropdownItem (click)="sourceSyncHandler(modalSelectedSourceListItem?.source)" type="button" class="btn btn-indigo">Sync</button>
        <button *ngIf="modalSelectedSourceListItem?.source?.platform_type != 'manual'" ngbDropdownItem (click)="sourceReconnectHandler(modalSelectedSourceListItem)" type="button" class="btn btn-danger">Reconnect</button>
        <button  ngbDropdownItem (click)="sourceDeleteHandler()" type="button" class="btn btn-danger">Delete</button>
      </div>
    </div>
    <button (click)="modal.dismiss('Close click')" type="button" class="btn btn-outline-light">Close</button>
  </div>
</ng-template>

<ng-template #isLoadingTemplate>
  <div class="row">
    <div class="col-12">
      <app-loading-spinner [loadingTitle]="'Please wait, loading sources...'"></app-loading-spinner>
    </div>
  </div>
</ng-template>
