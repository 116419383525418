<div class="az-content">
  <div class="container">
    <div class="az-content-body pd-lg-l-40 d-flex flex-column">

      <!-- Header Row -->
      <report-header [reportHeaderTitle]="'Explore'" [reportHeaderSubTitle]="'Explore your Medical Records'"></report-header>

      <ng-container [ngTemplateOutlet]="loading ? isLoadingTemplate :
      (connectedSources.length == 0) ? emptyReport : report"></ng-container>

      <ng-template #report>
        <div class="row">
          <app-medical-sources-card class="col-sm-3 mg-b-20 px-3"
                                    *ngFor="let sourceData of connectedSources"
                                    [sourceInfo]="sourceData"
                                    (onClick)="exploreSource($event)"
          ></app-medical-sources-card>
        </div>
      </ng-template>

      <ng-template #emptyReport>
        <div class="d-flex align-items-center" style="height:100%">
          <div class="modal-body tx-center pd-y-20 pd-x-20">
            <h4 class="tx-purple mg-b-20">No Sources Found!</h4>
            <p class="mg-b-20 mg-x-20">
              Fasten was unable to find any connected sources. You will need to connect a medical source before you can use this page.
            </p>
            <p class="mg-b-20 mg-x-20">
              Click below to add a new healthcare provider to Fasten.
            </p>
            <button [routerLink]="'/sources'" type="button" class="btn btn-purple pd-x-25">Add Source</button>
            <button [routerLink]="'/resource/create'" type="button" class="btn btn-purple mg-l-10 pd-x-25">Add Condition</button>

          </div><!-- modal-body -->
        </div>
      </ng-template>

      <ng-template #isLoadingTemplate>
        <div class="row">
          <div class="col-12">
            <app-loading-spinner [loadingTitle]="'Please wait, loading report...'"></app-loading-spinner>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</div>
