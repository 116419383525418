<div class="az-content az-content-app pd-b-0">
  <div class="container">
    <div class="az-content-body">
      <div class="az-content-breadcrumb">
        <span class="cursor-pointer" [routerLink]="['/explore', sourceId]">{{sourceName}}</span>
        <span>Resource</span>
        <span>{{resource?.source_resource_type}}</span>
        <span>{{resource?.source_resource_id}}</span>
      </div>

      <ng-container *ngIf="!loading else isLoadingTemplate">

        <fhir-card *ngIf="displayModel else noDisplayModel" [displayModel]="displayModel" [showDetails]="false"></fhir-card>

        <ng-template #noDisplayModel>
          <p> An error occurred while parsing FHIR resource </p>
        </ng-template>

        <div style="margin-bottom:50px" class="alert alert-warning" role="alert">
          Enable Debug mode: <input type="checkbox" [(ngModel)]="debugMode"/>
        </div>

        <ng-container *ngIf="resource && debugMode">
          <pre><code  [highlight]="resource.resource_raw | json"></code></pre>
        </ng-container>
      </ng-container>
      <ng-template #isLoadingTemplate>
        <div class="row">
          <div class="col-12">
            <app-loading-spinner [loadingTitle]="'Please wait, loading report...'"></app-loading-spinner>
          </div>
        </div>
      </ng-template>
    </div><!-- az-content-body -->
  </div>
</div>
