<ng-container [ngTemplateOutlet]="loading ? showLoading : (!loading && isEmpty) ? showEmpty : showChart"></ng-container>

<ng-template #showLoading>
  <loading-widget></loading-widget>
</ng-template>

<ng-template #showEmpty>
  <empty-widget></empty-widget>
</ng-template>

<ng-template #showChart>
  <div class="card card-dashboard-two h-100">
    <div class="card-header">
      <h6>{{chartDatasets?.[0] | datasetLatestEntry: 2:'data':'unit'  }} <i class="icon ion-md-trending-up tx-success"></i> <small>18.02%</small></h6>
      <p>{{widgetConfig?.title_text}}</p>
    </div><!-- card-header -->
    <div class="card-body d-flex flex-column">
      <div class="bounce-rate-chart-wrapper mt-auto mx-auto" style="position: relative; height:80%; width:100%">
        <canvas baseChart [type]="'line'" [datasets]="chartDatasets" [labels]="chartLabels" [options]="chartOptions"></canvas>
      </div>
    </div><!-- card-body -->
  </div>
</ng-template><!-- card -->
