<div class="d-flex h-100 card">
  <div class="mx-auto my-auto text-center">
    <div>
      <h4 class="tx-purple mg-b-20">No Data Found!</h4>
      <p class="mg-b-20 mg-x-20">
        Try adding a healthcare provider to Fasten.
      </p>
      <button [routerLink]="'/sources'" type="button" class="btn btn-purple pd-x-25">Add Source</button>
    </div>
  </div>
</div>
